import React from 'react'

import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="Ops, página não encontrada" />

      <Hero id="intro" />
    </Layout>
  )
}

export default NotFoundPage
